<template>
    <div class="item-page__comments">
        <div class="comment-send mb-11" v-if="canEdit">
            <v-textarea
                    no-resize
                    class="evi-textarea"
                    color="#44D370"
                    v-model="comment"
            >
                <template v-slot:label>
                    Комментарий
                </template>
            </v-textarea>
            <v-btn class="evi-button-green evi-button-green--fill mt-4" @click="sendComment" :disabled="!comment">
                Отправить
            </v-btn>
        </div>
        <v-dialog
                v-model="deleteCommentModal"
                max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Удаление комментария
                </v-card-title>
                <v-card-text>Вы действительно хотите удалить комментарий?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            @click="deleteCommentModal = false"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="red"
                            text
                            @click="removeComment"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="comments">
            <div class="comment" v-for="(item, index) in comments" :key="index">
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="comment__dots" v-on="on" :bind="attrs" v-if="canEdit">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </div>
                    </template>
                    <v-list class="comment__menu">
                        <v-list-item-group>
                            <v-list-item @click="deleteCommentModalOpen(item)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon color="#ED4245">mdi-trash-can-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <div class="comment__author">
                    <v-avatar
                            color="#FFFFFF"
                            size="40"
                            class="user-page__avatar mr-3"
                            @click="openUserPage(item.user.id)"
                    >
                        <img
                                :src="item.user.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                                alt=""
                        >
                    </v-avatar>
                    <div class="comment__linked" @click="openUserPage(item.user.id)">
                        {{item.user.name}}
                        <span class="title-line"/>
                    </div>
                </div>
                <v-textarea
                        no-resize
                        class="evi-textarea"
                        color="#44D370"
                        v-model="item.comment"
                        readonly
                >
                    <template v-slot:label>
                        Комментарий:
                    </template>
                </v-textarea>
                <div class="comments__date" v-if="item.created_at">
                    {{item.created_at | moment('DD.MM.YYYY в HH:mm')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";

    export default {
        name: "Comments",
        mixins: [OpenPagesMixin],
        props: {
            comments: {
                type: Array,
            },
            moduleItemId: {
                type: Number,
            },
            value : {
                type: String,
            },
            canEdit: {
                type: Boolean,
                default: true,
            }
        },
        data: () => ({
            deleteCommentModal: false,
            comment: '',
            commentId: null,
        }),
        methods: {
            deleteCommentModalOpen(item) {
                this.commentId = item.id;
                this.deleteCommentModal = true;
            },
            sendComment () {
                this.$store.dispatch(`${this.value}/createComment`, {
                    id: this.moduleItemId,
                    comment: this.comment,
                }).then((response) => {
                    if (response) {
                        this.comment = '';
                    }
                });
            },
            removeComment() {
                this.$store.dispatch(`${this.value}/deleteComment`, {
                    id: this.moduleItemId,
                    commentId: this.commentId,
                }).then((response) => {
                    if (response) {
                        this.$emit('getData');
                        this.deleteCommentModal = false;
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
.comments__date {
    font-size: 11px;
    color: $evi-grey-color;
}
</style>
