<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require('@/assets/img/pages/client.png')" alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="client.name"
            disabled
        >
          <template v-slot:label>
            Имя клиента
          </template>
        </v-text-field>
        <div class="item-page__actions mt-1">
          <v-btn text class="evi-button-text" @click="chat(client.id)" v-if="client.has_chat">
            <img class="mr-2" :src="require('@/assets/img/menu/chat.svg')" alt="">
            Перейти в чат
          </v-btn>
          <v-btn text class="evi-button-text" @click="addFieldModal = true"
                 v-if="hasRight('clients.manage')">
            <img width="19" class="mr-2" :src="require('@/assets/img/clients/field.svg')" alt="">
            Добавить поле
          </v-btn>
          <v-btn text class="evi-button-text" @click="addDealModal = true"
                 v-if="hasRight('deals.manage') && hasRight('clients.manage')">
            <img width="19" class="mr-2" :src="require('@/assets/img/menu/deals.svg')" alt="">
            Добавить сделку
          </v-btn>
          <v-btn text class="evi-button-text" @click="createBillModal = true"
                 v-if="hasRight('bills.manage')">
            <img width="19" class="mr-2" :src="require('@/assets/img/menu/bills.svg')" alt="">
            Выставить счёт
          </v-btn>
          <v-btn text class="evi-button-text" @click="createTaskModal = true"
                 v-if="hasRightInclude('tasks.')">
            <img width="19" class="mr-2" :src="require('@/assets/img/menu/tasks.svg')" alt="">
            Создать задачу
          </v-btn>
          <v-btn text class="evi-button-text" @click="removeClientModal = true" v-if="hasRight('clients.delete')">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <div class="mb-11">
          <h4 class="mb-5">Конверсия</h4>
          <div class="evi-toolbar__night mb-8" :class="{'active': isExcludedFromAnalytics}">
            <v-switch
                label="Не учитывать клиента в конверсии"
                v-model="isExcludedFromAnalytics"
                :readonly="!hasRight('clients.manage')"
                @change="editClientPage"
                hide-details
            >
              <template v-slot:label>
                <span class="mr-1 text-pre">Не учитывать в конверсии</span>
                <v-menu
                    top
                    offset-y
                    open-on-hover
                >
                  <template v-slot:activator="{ on, attrs }">
                    <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                  </template>
                  <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                    Клиент не будет учитываться в конверсии
                    <br>
                    сотрудника\организации\подразделений\каналов продаж
                  </v-list-item>
                </v-menu>
              </template>
            </v-switch>
          </div>
          <v-autocomplete
              class="evi-autocomplete mb-2"
              color="#44D370"
              :items="localUsers"
              v-model="responsibleUser"
              item-text="name"
              item-value="id"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              clearable
              cache-items
              item-color="green"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Ответственный
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect"/>
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{ item.name }}</div>
            </template>
            <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
        </div>
        <div class="mb-11" v-if="client.form_submission && client.integration">
          <h4 class="mb-5">Форма: {{client.integration.name}}</h4>
          <div class="item-page__text-field mb-5" v-for="f in client.form_submission.fields" :key="f.id">
            <small v-if="f.placeholder">{{ f.placeholder }}</small>
            <div v-if="f.placeholder" class="d-flex align-center">
              <span class="full-text" :title="f.value">{{ f.value }}</span>
            </div>
          </div>
        </div>
        <CustomFields class="mb-7" :items="clientCustomFields" :fieldDisabled="!hasRight('clients.manage')" :canRemoveField="hasRight('clients.manage') && hasRight('clients.viewAll') && hasRight('clients.delete')" @removeField="removeField" @editField="editField" @change="changeCustomField"/>
        <div v-if="hasRight('clients.manage')" class="add-field pointer mb-11" @click="addFieldModal = true">
          <v-icon>mdi-plus</v-icon>
          Добавить поле
        </div>
        <h4 class="mb-8">Общая информация</h4>
        <div class="item-page__text-field mb-7 pointer" @click="openPage"
             v-if="client.integration">
          <small class="mb-2">Интеграция (откуда пришел)</small>
          <div class="d-flex align-start">
            <img width="22" :src="require(`@/assets/img/integrations/${client.integration.type}.svg`)" alt=""/>
            <span>{{ client.integration.name }}</span>
          </div>
        </div>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="client.id"
            disabled
            hide-details
        >
          <template v-slot:label>
            ID клиента
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="name"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            Имя клиента
          </template>
        </v-text-field>
        <v-menu
            v-model="menuNextContact"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :key="nextContactDate"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="nextContactDateFormatted"
                v-bind="attrs"
                v-on="on"
                readonly
                label="Дата следующего контакта"
                color="#44D370"
                class="evi-text-field mb-7"
                :key="nextContactDate"
                hide-details
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="nextContactDate"
              @input="menuNextContact = false"
              color="#44D370"
              first-day-of-week="1"
              @change="editClientPage"
              :key="nextContactDate"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="statuses"
            v-model="clientStatus"
            item-text="name"
            item-value="id"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            clearable
            item-color="green"
            :key="statuses.length"
            hide-details
        >
          <template v-slot:label>
            Статус
          </template>
          <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="modal-create__color-select">
              <span class="color" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="modal-create__color-select">
              <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="tags"
            v-model="clientTags"
            multiple
            item-text="name"
            item-value="id"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            clearable
            item-color="green"
            hide-details
        >
          <template v-slot:label>
            Теги
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="evi-autocomplete__tag mr-2 mt-2" :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="evi-autocomplete__tag" :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="client.gender"
            :items="genderVariants"
            label="Пол"
            item-color="green"
            item-text="name"
            item-value="value"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
          </template>
        </v-select>
        <div>
          <h4 class="mb-7">Контакты</h4>
        </div>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="email"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            Email
          </template>
        </v-text-field>
        <div class="item-page__text-field mb-7 email-message" v-if="client.first_email_message">
          <small class="mb-2">Первое сообщение с почты</small>
          <div class="d-flex align-center" v-html="client.first_email_message">
          </div>
        </div>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="phone"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            Номер телефона
          </template>
        </v-text-field>
        <div class="item-page__text-field mb-7 pointer" v-if="client.last_deal && client.last_deal.executor"
             @click="openUserPage(client.last_deal.executor.id)">
          <small>Исполнитель последней сделки</small>
          <div class="d-flex align-center">
            <span>{{ client.last_deal.executor.name }}</span>
          </div>
        </div>
        <div class="item-page__text-field mb-7 pointer" v-if="client.last_deal"
             @click="openDealPage(client.last_deal.id)">
          <small>Последняя сделка</small>
          <div class="d-flex align-center">
            <span>{{ client.last_deal.name }}</span>
          </div>
        </div>
        <div class="item-page__text-field mb-7" v-if="client.last_deal && client.last_deal.deal_status">
          <small>Статус последней сделки</small>
          <div class="d-flex align-center">
            <span
                :style="{color: `${client.last_deal.deal_status.color} !important`}">{{
                client.last_deal.deal_status.name
              }}</span>
          </div>
        </div>
        <div class="item-page__text-field mb-7" v-if="client.first_contact_date">
          <small>Дата первого контакта</small>
          <div class="d-flex align-center">
            <span>{{ client.first_contact_date | moment('DD.MM.YYYY [в] HH:mm') }}</span>
          </div>
        </div>
        <div class="item-page__text-field mb-7" v-if="client.last_contact_date">
          <small>Дата последнего контакта</small>
          <div class="d-flex align-center">
            <span>{{ client.last_contact_date | moment('DD.MM.YYYY [в] HH:mm') }}</span>
          </div>
        </div>
        <div class="item-page__text-field mb-7" v-if="client.first_message_answer_time">
          <small>Время ответа сотрудником на 1-ое сообщение клиента</small>
          <div class="d-flex align-center">
            <span>{{ returnTimeAnswer(client.first_message_answer_time) }}</span>
          </div>
        </div>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="client.country"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            Страна
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="city"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            Город
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="address"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            Адрес
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="birthday"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            v-mask="`##.##.####`"
            hide-details
        >
          <template v-slot:label>
            Дата рождения
          </template>
        </v-text-field>

        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="inn"
            @change="editClientPage"
            type="number"
            :disabled="!hasRight('clients.manage')"
            hide-details
        >
          <template v-slot:label>
            ИНН
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="kpp"
            @change="editClientPage"
            :disabled="!hasRight('clients.manage')"
            type="number"
            hide-details
        >
          <template v-slot:label>
            КПП
          </template>
        </v-text-field>

        <div class="mt-11">
          <h4 class="mb-5">Соц. сети</h4>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.vk_id"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              VK ID
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.vk_url"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Ссылка на страницу VK
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.vk_login"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Имя VK
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.instagram_id"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Instagram ID
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.instagram_url"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Ссылка на страницу Instagram
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.instagram_login"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Логин Instagram
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.telegram_id"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Telegram ID
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.telegram_url"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Ссылка на чат Telegram
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="client.telegram_login"
              @change="editClientPage"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:label>
              Логин Telegram
            </template>
          </v-text-field>
        </div>

        <div v-if="client.vk_ref || client.vk_ref_source">
          <h4 class="mb-5">VK UTM</h4>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-if="client.vk_ref"
              v-model="client.vk_ref"
              disabled
          >
            <template v-slot:label>
              Название кампании (ref)
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-if="client.vk_ref_source"
              v-model="client.vk_ref_source"
              disabled
          >
            <template v-slot:label>
              Название рекламной площадки (ref_source)
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="item-page__added">
        <v-tabs
            v-model="tab"
            align-with-title
            class="item-page__tabs"
        >
          <v-tabs-slider color="#44D370"></v-tabs-slider>
          <v-tab
              v-for="item in tabs"
              :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <div class="chat-sidebar__deals d-flex flex-column align-start" v-if="tab === 0">
          <v-btn class="evi-button-green evi-button-green--fill mb-11" @click="addDealModal = true" v-if="hasRight('deals.manage')">
            Добавить сделку
          </v-btn>
          <div class="w-100" v-if="localDealsLoading">
            <v-skeleton-loader
                type="list-item-three-line"
                width="100%"
                height="100%"
                v-for="item of 3"
                :key="item"
            />
          </div>
          <div class="w-100" v-else-if="!localDealsLoading && deals.length">
            <router-link class="chat-sidebar__deal w-100" v-for="i in deals" :key="i.id" :to="{name: 'Deal-Page', params: {id: i.id}}">
              <img width="26" :src="require('@/assets/img/menu/deals.svg')" alt="">
              <div class="d-flex flex-column">
                <div class="item-page__text-field">
                  <small>Название</small>
                  <div class="d-flex align-center">
                    <span class="item-page__deal-name">{{ i.name }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <span class="empty-data" v-else-if="!localDealsLoading && !deals.length">
            Сделок нет
          </span>
        </div>
        <Comments
            v-else-if="tab === 1"
            :comments="client.comments"
            :moduleItemId="client.id"
            value="clients"
            @getData="getData"
            :can-edit="hasRight('clients.manage')"
        />
      </div>
    </div>
    <v-dialog
        v-model="removeClientModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление клиента: "{{ client.name }}"
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить клиента?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeClientModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeClient"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addDealModal"
        max-width="612px"
    >
      <CreateDeal @updateDeals="getData" :currentClient="client" @close="addDealModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="addFieldModal"
        max-width="612px"
    >
      <CreateField @createField="createClientCustomFields" @close="addFieldModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createBillModal"
        max-width="612px"
    >
      <CreateBill :currentClient="client" @close="createBillModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createTaskModal"
        max-width="718px"
    >
      <CreateTask :currentClient="client" @closeAfterCreate="createTaskModal = false"
                  @close="createTaskModal = false"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Comments from "../../components/сommon/Comments";
import CreateDeal from "../../components/deals/CreateDeal";
import {TimeAnswerMixin} from "@/mixins/TimeAnswerMixin";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {hasRight} from '@/utils/access/hasRight';
import {hasRightInclude} from '@/utils/access/hasRight';
import CreateTask from "../../components/tasks/CreateTask";
import CreateBill from "../../components/bills/CreateBill";
import CreateField from "../../components/clients/CreateField";
import CustomFields from "../../components/clients/CustomFields";

export default {
  name: "ClientPage",
  components: {CustomFields, CreateField, CreateBill, CreateTask, CreateDeal, Comments},
  mixins: [TimeAnswerMixin, UserAutocompleteRequestsMixin, OpenPagesMixin],
  data: () => ({
    name: '',
    email: '',
    phone: '',
    inn: '',
    kpp: '',
    address: '',
    responsibleUser: null,
    removeClientModal: false,
    addDealModal: false,
    createTaskModal: false,
    createBillModal: false,
    clientTags: [],
    clientStatus: null,
    addFieldModal: false,
    isExcludedFromAnalytics: false,
    birthday: '',
    city: '',
    nextContactDate: '',
    menuNextContact: false,
    localDealsLoading: false,
    genderVariants: [
      {value: 'm', name: 'Мужской',},
      {value: 'f', name: 'Женский',},
    ],
    tab: 0,
    tabs: [
      'Сделки', 'Комментарии',
    ],
  }),
  computed: {
    ...mapState("clients", ['client', 'clientCustomFields']),
    ...mapState("common", ["loading"]),
    ...mapState("user", ["profile"]),
    ...mapState("tags", ["tags",]),
    ...mapState("deals", ["deals",]),
    ...mapState("client-statuses", ["statuses",]),
    localUsers() {
      let array = [];
      array.push(this.profile);
      if (this.client.responsible_user) array.push(this.client.responsible_user);
      this.users.forEach(i => {
        if (i.id !== this.profile.id) {
          array.push(i);
        }
      });
      return array;
    },
    nextContactDateFormatted() {
      if (this.nextContactDate) {
        return this.$moment(this.nextContactDate).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      getClient: 'clients/getClient',
      editClient: 'clients/editClient',
      deleteClient: 'clients/deleteClient',
      getClientChat: 'chat/getClientChat',
      getClientStatuses: 'client-statuses/getStatuses',
      getTags: 'tags/getItems',
      getClientCustomFields: 'clients/getClientCustomFields',
      deleteClientCustomFields: 'clients/deleteClientCustomFields',
      editClientCustomFields: 'clients/editClientCustomFields',
      createClientCustomFields: 'clients/createClientCustomFields',
      getDealsPages: 'deals/getDealsPagesWithoutLoading',
    }),
    hasRight: hasRight,
    hasRightInclude: hasRightInclude,
    removeField(i) {
      this.deleteClientCustomFields({id: i.id, index: i.index})
    },
    editField(i) {
      this.editClientCustomFields(i)
    },
    getDeals() {
      if (this.hasRight('deals.viewAll')) {
        this.localDealsLoading = true;
        this.getDealsPages({
          per_page: 20,
          page: 1,
          'filter[client_id]': this.client.id,
        }).finally(() => this.localDealsLoading = false);
      } else if (this.hasRight('deals.viewOwn')) {
        this.localDealsLoading = true;
        this.getDealsPages({
          per_page: 20,
          page: 1,
          'filter[client_id]': this.client.id,
          'filter[executor_id]': this.profile.id,
        }).finally(() => this.localDealsLoading = false);
      }
    },
    getData() {
      let clientId = this.$route.params.id;
      this.getClient(clientId).then(() => {
        this.getDeals();
      });
      this.getClientStatuses();
      this.getTags();
    },
    editClientPage() {
      let payload = {
        name: this.name || this.client.name,
        email: this.email,
        phone: this.phone,
        inn: this.inn,
        kpp: this.kpp,
        address: this.address,
        id: this.client.id,
        tags: this.clientTags,
        birth: this.birthday,
        gender: this.client.gender,
        city: this.city,
        country: this.client.country,
        client_status_id: this.clientStatus,
        responsible_user_id: this.responsibleUser,
        next_contact_date: this.nextContactDate,
        is_excluded_from_analytics: this.isExcludedFromAnalytics,
        //соц сети
        vk_id: this.client.vk_id,
        vk_url: this.client.vk_url,
        vk_login: this.client.vk_login,
        instagram_id: this.client.instagram_id,
        instagram_url: this.client.instagram_url,
        instagram_login: this.client.instagram_login,
        telegram_id: this.client.telegram_id,
        telegram_url: this.client.telegram_url,
        telegram_login: this.client.telegram_login,
      };
      this.editClient(payload);
    },
    changeCustomField (val) {
      if (val.value) {
        let payload = {
          id: this.client.id,
          custom_fields: [val]
        };
        this.editClient(payload);
      }
    },
    removeClient() {
      this.deleteClient(this.client).then(() => {
        this.removeClientModal = false;
        this.$router.push({path: '/clients'});
      })
    },
    openUserPage(id) {
      this.$router.push({path: `/users/${id}`});
      this.getData();
    },
    chat(id) {
      this.getClientChat(id).then((response) => {
        this.$router.push({path: `/chat/clients/${response.id}`});
      });
    },
    openPage () {
      if (this.client.integration.type !== 'form') {
        this.openIntPage(this.client.integration.id);
      } else {
        this.openIntFormPage(this.client.integration.id);
      }
    },
  },
  watch: {
    client(val) {
      this.getClientCustomFields();

      this.name = val.name;
      this.isExcludedFromAnalytics = val.is_excluded_from_analytics;
      this.email = val.email;
      this.phone = val.phone;
      this.inn = val.inn;
      this.kpp = val.kpp;
      this.address = val.address;
      this.clientTags = val.tags.map(i => i.id);
      this.birthday = val.birth;
      this.city = val.city;
      if (val.client_status) {
        this.clientStatus = val.client_status.id;
      }
      if (val.next_contact_date) {
        this.nextContactDate = this.$moment(val.next_contact_date).format('YYYY-MM-DD');
      }
      if (this.client.responsible_user) {
        this.responsibleUser = this.client.responsible_user.id;
      }

      if (val.form_submission) {
        val.form_submission.fields.forEach(f => {
          f.placeholder = val.form_submission.form_fields.find(i => i.name === f.name).placeholder;
        });
      }
    },
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
    'clientCustomFields': {
      handler: function (val) {
        // значения кастомных полей устанавливается тут
        if (this.client.custom_fields && this.client.custom_fields.length) {
          this.client.custom_fields.forEach(i => {
            let field = val.find(f => f.id === i.id);
            if (field) {
              if (field.type === 'datetime') {
                field.value = `${this.$moment(i.value).format('DD.MM.YYYY')} ${this.$moment(i.value).format('HH:mm')}`;
              } else {
                field.value = i.value;
              }
            }
          });
        }
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.item-page {
  &__edit {
    border-right: 1px solid #EBEBEC;
  }

  .add-field {
    margin-left: -5px;
  }

  .chat-sidebar__deals {
    overflow-y: visible;
  }
  .chat-sidebar__deal {
    max-width: 450px;
  }
}
</style>
